import React from 'react';
import { Link } from 'gatsby';
import {
    Container,
    Divider,
    Header,
    Image,
} from 'semantic-ui-react';
import Layout from '../components/layout';
import Helmet from '../components/Helmet';
import PieceOfCake from '../images/404.png';


const NotFoundPage = () => (
    <Layout
        showDisclosures={false}
    >
        <Helmet
            title="Page Not Found"
            description="This page was not found."
            robots="noindex, follow"
        />
        <Container>
            <Divider hidden />
            <Header size="large" className="no-margin">404: Page Not Found</Header>
            <p>We searched and searched but the page you&rsquo;re looking for just couldn&rsquo;t be found. We hope this piece of cake helps you cope.</p>
            <Image src={PieceOfCake} />
            <Divider hidden />
            <Header size="large" className="no-margin">But not to worry! </Header>
            <p>
                {' '}
                <Link to="/">Visit our homepage</Link>
                {' '}
                to find what you&rsquo;re looking for.
            </p>
        </Container>
    </Layout>
);

export default NotFoundPage;
